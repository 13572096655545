import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Thumbs, Pagination } from 'swiper';
import classNames from 'classnames';
import ArticleCard from 'gatsby-theme-husky/src/components/ArticleCard';
import Button from 'gatsby-theme-husky/src/common/Button';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import { IRelatedArticlesList } from './models';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import 'swiper/components/navigation/navigation.scss';
import './RelatedArticles.scss';
import './RelatedArticlesRTL.scss';

SwiperCore.use([Navigation, Pagination, Thumbs]);

const RelatedArticles: FC<IRelatedArticlesList> = ({
  data,
  title,
  subtitle,
  readMoreText,
  ariaLabel,
  breakpoints,
  articlesCount = 2,
  sliceTitle,
  readAllLink,
  showTags,
}) => {
  const { isMobile } = useScreenRecognition();

  return (
    <div className="related-articles">
      {title ? <h2 className="related-articles__title">{title}</h2> : null}
      {subtitle ? (
        <DangerouslySetInnerHtml className="related-articles__subtitle" html={subtitle} />
      ) : null}

      {isMobile ? (
        <div>
          <Swiper slidesPerView={1} pagination={{ clickable: true }} breakpoints={breakpoints}>
            {data.map((article) => (
              <SwiperSlide key={article.id}>
                <ArticleCard
                  article={article}
                  readMoreText={readMoreText}
                  ariaLabel={ariaLabel}
                  showTags={showTags}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div
          className={classNames('related-articles__wrapper', {
            'two-columns': articlesCount === 2,
            'three-columns': articlesCount === 3 || articlesCount === 4,
          })}
        >
          {data.slice(0, articlesCount).map((article) => (
            <ArticleCard
              key={article.id}
              article={article}
              readMoreText={readMoreText}
              ariaLabel={ariaLabel}
              sliceTitle={sliceTitle}
              showTags={showTags}
            />
          ))}
        </div>
      )}
      {readAllLink?.[0]?.name ? (
        <Button
          ariaLabel={readAllLink[0].name}
          link={readAllLink?.[0]?.url}
          className="related-articles__read-all"
        >
          {readAllLink[0].name}
        </Button>
      ) : null}
    </div>
  );
};

export default RelatedArticles;
